.delete-confirmation-dialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: black;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  